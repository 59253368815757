const images = [
  {
    imageUrl: "/kirs-01.jpg",
    date: "Sep 13",
    cameraSettings: "bing bon",
  },
  {
    imageUrl: "/kirs-02.jpg",
    date: "Sep 13",
    cameraSettings: "bing bon",
  },
  {
    imageUrl: "/kirs-03.jpg",
  },
  {
    imageUrl: "/kirs-04.jpg",
  },
  {
    imageUrl: "/kirs-05.jpg",
  },
  {
    imageUrl: "/kirs-06.jpg",
  },
  {
    imageUrl: "/kirs-07.jpg",
  },
  {
    imageUrl: "/kirs-08.jpg",
  },
  {
    imageUrl: "/kirs-09.jpg",
  },
  {
    imageUrl: "/kirs-10.jpg",
  },
];

export function getImages() {
  return images;
}
